<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Provider Contract</h1>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Qasim</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>    
        <li
          @click="editTariffSummary('pricing')"
          :class="tariffSummary == 'pricing' ? 'active' : ''"
        >
          <span>Contract</span>
        </li>
      </ul>
    </nav>

    <div class="route-content">
      
      <div v-if="tariffSummary == 'pricing'">
        <!-- h1 class="text-3xl my-6 active">Procedures</h1 -->
        <!--table id="fixedheight" v-if="specialityData !== null" -->
        <table class="table-primary border-fix">
                <thead>
                  <tr>
                    <th class="border px-4 py-2">Description</th>
                    <th class="border px-4 py-2">Link</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border" style="padding: 10px;">For the draft contract with key commissioning and contracting volumes, pricing and management clink to link</td>        
                    <td class="border" style="padding: 10px;"><a href="https://healthcare-insight.com/Contract">draft contract link<div style="height:100%;width:100%"></div></a></td>
                  </tr>                                   
                </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tariffSummary: "pricing",
      selectedSpeciality: "AHPs",
      message: "<BR />",
    };
  },
  mounted() {
    console.log("PricingMain.Vue: mounted");
    const speciality = {
      speciality: "",
      apiString: "tariff_pricing_collection",
    };
    this.fetchData();
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return true;
      }
      return null;
    },
    packageSpecialityFilter() {
      // 1
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].packagePricing;
      }
      return null;
    },
    procedureDetailFilter() {
      // 2
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].procedureDetail;
      }
      return null;
    },
    procedureOPD() {
      // 3
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].opd);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].opd;
      }
      return null;    
    },
    procedureInvestigations() {
      // 4
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].investigations);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].investigations;
      }
      return null;    
    },
    procedurePrice() {
      // 5
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].procurePrice);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].procedurePrice;
      }
      return null;    
    },
    procedureALOSICU() {
      // 6
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].alos_icu);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].alos_icu;
      }
      return null;    
    },
    procedurePriceICU() {
      // 7
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].price_icu);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].price_icu;
      }
      return null;    
    },
    procedureALOSCCUHD() {
      // 8
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].alos_ccuhd);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].alos_ccuhd;
      }
      return null;    
    },
    procedurePRICECCUHD() {
      // 9
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].price_ccuhd);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].price_ccuhd;
      }
      return null;    
    },
    procedureALOSGeneral() {
      // 10
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].alos_general);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].alos_general;
      }
      return null;    
    },
    procedurePriceGeneral() {
      // 11
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].price_general);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].price_general;
      }
      return null;    
    },
    procedureALOSRehabilitation() {
      // 12
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].alos_rehabilitation);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].alos_rehabilitation;
      }
      return null;    
    },
    procedurePriceRehabiliation() {
      // 13
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].price_rehabilitation);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].price_rehabilitation;
      }
      return null;    
    },
    procedureFollowUp() {
      // 14
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].follow_up_opd);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].follow_up_opd;
      }
      return null;    
    },
    procedureTotalPrice() {
      // 15
      console.log ("proc: ", this.$store.state.prototypedata.packagePricing[0].total_price);
      if (this.$store.state.prototypedata.packagePricing.length > 0) {
        return this.$store.state.prototypedata.packagePricing[0].total_price;
      }
      return null;    
    },
   
   
  },
  methods: {
    fetchData(speciality) {
      console.log("PricingMAin.Vue: fetchData");
      //this.specialityHeader(speciality.speciality);
      this.$store
        .dispatch("getBDFPackagePricing")
        .then((data) => {
          console.log("data", data);
        });
    },    
    editTariffSummary(tariffSummary) {
      this.tariffSummary = tariffSummary;
    },
  },
};
</script>
<style>
    #fixedheight {
        table-layout: fixed;
    }

    #fixedheight thead {
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      background: rgba (229, 233, 242, 0.5);
      padding: 1rem;
    }
    
    #fixedheight td {
        width: 25%;
    }
    
    #fixedheight td div {
        height: 20px;
        overflow: hidden;
    }
</style>
