<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Health Insurance Package
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Qasim</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
<ul>
  <li>
      <a href="#">Beneficiaries</a>
  </li>                
<li
@click="editStageSummary('executive summary')"
:class="stageSummary == 'executive summary' ? 'active' : ''">
<span>Table of Benefits</span>
</li>
<!--li
@click="editStageSummary('intro & strategic approach')"
:class="stageSummary == 'intro & strategic approach' ? 'active' : ''">
<span>Table of Benefits</span>
</li-->
<!--li
@click="editStageSummary('strategic situation')"
:class="stageSummary == 'strategic situation' ? 'active' : ''">
<span>Strategic Situation</span>
</li>
<li
@click="editStageSummary('strategic factors')"
:class="stageSummary == 'strategic factors' ? 'active' : ''">
<span>Strategic Factors</span>
</li>
<li
@click="editStageSummary('aim')"
:class="stageSummary == 'aim' ? 'active' : ''">
<span>Aim</span>
</li>
<li
@click="editStageSummary('strategic execution')"
:class="stageSummary == 'strategic execution' ? 'active' : ''">
<span>Strategic Execution</span>
</li>
<li
@click="editStageSummary('strategic ex phase 1')"
:class="stageSummary == 'strategic ex phase 1' ? 'active' : ''">
<span>Strategic Ex Phase 1</span>
</li>
<li
@click="editStageSummary('strategic ex phase 2')"
:class="stageSummary == 'strategic ex phase 2' ? 'active' : ''">
<span>Strategic Ex Phase 2</span>
</li>
<li
@click="editStageSummary('strategic ex phase 3')"
:class="stageSummary == 'strategic ex phase 3' ? 'active' : ''">
<span>Strategic Ex Phase 3</span>
</li-->
<li>
      <a href="#">MMS GCC Comparison</a>
  </li>  
  <li>
      <a href="#">Admin Service TPA</a>
  </li>  
  <li>
      <a href="#">Desired Provider Outcomes TPA</a>
  </li>  
  <li>
      <a href="#">Service Levels TPAs</a>
  </li>  
  <li>
      <a href="#">NHS Style vs Hybrid Contracts</a>
  </li> 
  <li>
      <a href="#">TPA Provider Agreement</a>
  </li> 
</ul>
</nav>
  


 
<div v-if="stageSummary == 'executive summary'">
<div class="route-content">
<!--div class="new_stat-cards" -->
<!---- insert code here -------->

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Plan Name</th>
          <th class="border px-4 py-2">Thiqa_UAE Nationals and those of similar status_Northern Emirates and Dubai Emirate</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Benefit Per Insured</td>
          <td class="border" style="padding: 10px; ">Policy period 12 months with no limitations as to time</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Territorial Limit</td>
          <td class="border" style="padding: 10px; ">Outside Abu Dhabi Emirate Medical emergencies only<br><br>(Full coverage in the Abu Dhabi Emirate)</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Network (Allowing free access at designated Providers)</td>
          <td class="border" style="padding: 10px; ">Daman Network- Abu Dhabi</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">In-Patient & Day Treatment require pre-authorization in the Private sector only</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">(Pre & Post In-Hospital Treatment Covered 100%)</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Direct claims will not be reimbursed unless proven emergency cases.	</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Accommodation Type	</td>
          <td class="border" style="padding: 10px; ">Room with one bed (private)</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Hospital Accommodation & Services</td>
          <td class="border" style="padding: 10px; ">Covered at 100%<br>No financial limitation</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Consultant’s, Surgeon’s & Anesthetist’s Fees and other fees</td>
          <td class="border" style="padding: 10px; ">Providers' contracted physician's fees are covered at 100 % as per DAMAN contract.</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Home Nursing</td>
          <td class="border" style="padding: 10px; ">Maximum limit of authorization is 60 days subject to extension. Home nursing as defined by international standard<br>
            (Authorization is required)<br></td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Ambulance and evacuation   (in Medical Emergency only, subject to Standard Exclusions)</td>
          <td class="border" style="padding: 10px; ">Covered at 100%<br>
            (emergency evacuation while abroad is not covered )</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Escort accommodation, parents or otherwise as determined by the treating doctor</td>
          <td class="border" style="padding: 10px; ">Covered at 100%</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Out-Patient Treatment</td>
          <td class="border" style="padding: 10px; "></td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Diagnostic tests, X-rays and laboratories</td>
          <td class="border" style="padding: 10px; ">Covered at 100%</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">MRI, CT, PET scan and other similar tests</td>
          <td class="border" style="padding: 10px; ">Covered at 100% only if prescribed by a specialist</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">(Require an authorization in private network providers only)</td>
          <td class="border" style="padding: 10px; "></td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Pharmaceuticals</td>
          <td class="border" style="padding: 10px; ">Covered at 50% in private providers<br><br>(Long term medications to be dispensed, exceeding 90 days require an authorization in private network providers only)</td>
</tr><tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Medical appliances and equipments</td>
          <td class="border" style="padding: 10px; ">Covered at 100% according to HAAD list of required<br> items for approvals</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Out-Patient psychiatric treatment<br>(With pre-authorization in private network providers only )</td>
          <td class="border" style="padding: 10px; ">Covered at 100%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Dietitian<br>(With pre-authorization in private network providers only )	</td>
          <td class="border" style="padding: 10px; ">Covered at 100%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Physiotherapy<br>(With pre-authorization in private network providers only)</td>
          <td class="border" style="padding: 10px; ">	Physiotherapy 100%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Emergency Treatment	</td>
          <td class="border" style="padding: 10px; ">Covered at 100 % within UAE territory (Private and public network providers)</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Repatriation of Mortal Remains to the Country of Origin (Including family members)<br>Maternity	</td>
          <td class="border" style="padding: 10px; ">Not covered</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">All In-Patient Maternity Treatments are subject to pre-authorization in Private Network providers only.	</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">In-Patient Maternity</td>
          <td class="border" style="padding: 10px; "> -     Covered at 100%<br>
	-       Maternity complications are covered at 100%<br>
	-       Congenital coverage 100%<br>
	-       Birth Defects coverage 100%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Out-Patient Maternity	</td>
          <td class="border" style="padding: 10px; ">Covered at 100%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Dental</td>
          <td class="border" style="padding: 10px; ">Covered at 50% in private providers
	(and 100% at “SEHA’ providers)<br><br>
	
	Bridge works, crowns, orthodontics, dentures, implants require authorization in private Network providers only.
	Other dental services are covered 50%</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Sterile services<br>(Male and Female)	</td>
          <td class="border" style="padding: 10px; ">With a maximum of 3 attempts</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Other Services</td>
          <td class="border" style="padding: 10px; ">International Assistance in case of emergency is not available</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Other Benefits</td>
          <td class="border" style="padding: 10px; ">Rehabilitation of Non Excluded conditions is covered at 100% and requires an<br> authorization (only in private sector) and will be<br> given up to 90 days per policy per member resulting from recent acute condition and can be extended<br> if necessary.Any medical treatment or intervention essential for the status of the patient</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Public Network providers</td>
          <td class="border" style="padding: 10px; ">All treatment, medicine, medical equipment, including<br>hospital in patient services, maternity, dental, home<br>nursing and all services listed in the schedule of<br> benefits and services in outpatient clinics do not<br> require preauthorization.
          </td>
</tr>
</tbody>
</table>

<!-- 

	
	
	

-->
</div>
</div>
<div v-if="stageSummary == 'intro & strategic approach'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">2 - Introduction</div>

<table class="table-primary">
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.1</td>
          <td class="border" style="padding: 10px; ">The delivery of operational improvement of all services requires the integration of all key system<br>organisational pillars to deliver complete transformation outputs to support the development of the improvement Strategy.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.2</td>
          <td class="border" style="padding: 10px; ">The establishment of long-term continuous operational improvement will require a dedicated focus<br>on understanding total demand versus requisite capacity.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.3</td>
          <td class="border" style="padding: 10px; ">The management of resources in an ongoing process – effectively daily – is essential to match<br>resources allocation to operational demand.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2.4</td>
          <td class="border" style="padding: 10px; ">The essential component will be the implementation of thorough and intelligent operational<br>planning process affecting all clinical and non-clinical services.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Additional planning details and developments will be incorporated into this document for<br>guidance where agreed.</td>
</tr>
</tbody>
</table>

<div class="hello1">3 - Approach</div>

<table class="table-primary">
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.1</td>
          <td class="border" style="padding: 10px; ">Methodology.  Understanding the current operational performance has involved a series of <br>methodologies.  This has enabled us to very quickly establish themes and patterns of operational<br>performance and delivery, indicating productivity against asset utilisation and occupancy, as well<br>as the impact on workforce.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.2</td>
          <td class="border" style="padding: 10px; ">Daily Monitoring of Activity</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.3</td>
          <td class="border" style="padding: 10px; ">Daily Data Demand twice per day.  The gradual increase of information flow at several points<br>during each day enables complete oversight over actual activity, utilisation, occupancy and<br>balance against deployed resources.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.4</td>
          <td class="border" style="padding: 10px; ">Asset Benchmarking.  Supporting data flow on current performance, we have conducted detailed<br>Asset benchmarking on each hospital.  This has involved looking at 100% utilisation of all assets,<br>calculating total workforce at 100% occupancy, and indicating the full productivity potential at full<br>utilisation.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3.5</td>
          <td class="border" style="padding: 10px; ">The benchmark is then conducted against actual productivity, asset and workforce deployment<br>which presents an accurate indicator of total Demand Capacity</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>

<div v-if="stageSummary == 'strategic situation'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="new_stat-cards--item single no-hover" style="width: 50%">
            <span class="card-header">Summary Analysis</span>
            <span class="card-total3">The summary analysis identified from modelling and daily data analysis points to the following outputs:<br>
Utilization is low across the Group (overall less than 50%, except for BOC).<br>
Employed workforce is deployed at more than 100% occupancy levels.<br>
Assets are underutilised.  The lack of planning or efficiency operational delivery generates wasted asset cost.<br>
Productivity is low versus capacity: in this context the presence of waiting lists is ridiculous.<br>
Financial Costbase is too high versus the levels of productivity.  Workforce % of Income is over 80%: much higher than hospital/industry norms.<br>
In Sum:  Throughout the Group, inefficient use of valuable resources such as Operating Theatres, Outpatients Clinics, beds etc.<br>is evident.  Future improvement of operations will require a complete ‘operational grip’ to be in place.
</span>            
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 50%">
            <span class="card-header">Beds</span>
            <span class="card-total3">Clear imbalance between operational productivity and available capacity<br>
Bed capacity has significant underutilisation in BDF Military Hospital and MKCC.<br>
Normal utilisation is apparent in KHUH and BOC.  However certain areas of KHUH are underutilised constantly.<br>
Nurse Staffing is as per 100% utilisation in all areas.<br>
KHUH ICU beds high occupancy – however this is caused by misuse of ICU beds by Palliative Care from BOC.<br>
BDF ICU – always around 90/100% occupancy.  Plan is in place to increase capacity to 21 from 11.<br><br>
KHUH Medical Beds – under pressure at regular 90%.  
</span> 
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 50%">
            <span class="card-header">Radiology</span>
            <span class="card-total3">Capacity is medium level across the group,<br>
Certain modalities are over-utilised and waiting lists evident.<br>
Operating Theatres and Intervention (Cath Labs, Endoscopy)<br>
All hospitals have low utilisation of operating theatres versus capacity.<br>
Operating efficiency is low.  Specific Surgeons plan and operate effective theatres use rather than being systematically driven.<br>
The average volume of cases per day is 2.3 in BDFH, and 3.1 in KHUH.  MKCC is less than 1.<br>
All capacity is generally low utilisation and not efficiently planned and managed.<br>
Endoscopy is low productive in the 2 centres:<br>
BDF Hospital does not require the second planned endoscopy room.  It has less than 50% productivity and has more than<br>4 times the normal staffing allocated.<br>
KHUH delivers x3 the caseload from one of its 4 endoscopy units; albeit with the same volume of endoscopists available (2).<br>
</span> 
          </div>

          <div class="new_stat-cards--item single no-hover" style="width: 50%">
            <span class="card-header">Outpatient Clinics</span>
            <span class="card-total3">The following aspects of the operation of Outpatient Clinics are apparent:<br>
Imbalance between New and Follow-Up patients, by 1:4.<br>  
Many patients are booked for Pharmacy replication.  This is a waste of valuable resources.<br>
Both main Hospitals have an excess of Nursing and Practical Nursing staff in the Outpatient Clinics.<br>
Clinics start without Doctors present, due to being dual-roled in bed management or operating theatres.<br>  
Clinics are not planned effectively.<br>
No management of the operation is present, so patients are often left unattended.<br>
Patient activity fluctuates wildly between Consultants and Doctors.<br><br>
Patients are not effectively discharged after 2 Follow-ups.<br><br>
There are far too many OPD clinics opened.<br>
KHUH has a major issue of relocating clinics to establish a clear differentiation with BOC, however this project to relocate<br>must be linked to a significant reduction in the volume of patients attending for unnecessary follow-ups.
</span> 
        </div>


</div>
</div>
</div>





<div v-if="stageSummary == 'strategic factors'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">Key Factors - So What?</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
        
            <span class="card-total3">The outputs indicate a significant amount of asset and workforce capacity currently expended however with little output.  	
We have assessed why this is the case, and have indicated a series of key factors:	
</span>            
          </div>


          <div class="hello1">Operational Weakness Factors</div>


          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item </th>
          <th class="border px-4 py-2">Operational Weakness Factor</th>
          <th class="border px-4 py-2">Consideration</th>
          <th class="border px-4 py-2">Deduction</th>
          <th class="border px-4 py-2">So What?</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Lack of operational Planning</td>
          <td class="border" style="padding: 10px; ">All activity is short term.  No decision-makers involved in OT planning, OPD planning, Bed Management, Diagnostics.  This enables Medical staff to work at their own pace without supervision or appropriate governance.  This leads to Nursing staffs being deployed in OPD and OT without sufficient activity to justify</td>
          <td class="border" style="padding: 10px; ">Lack of Planning leads to:<br />·Asset wastage<br />·Workforce Excess<br />·Financial cost</td>
          <td class="border" style="padding: 10px; ">Operational Improvement will focus on comprehensive Activity planning integrated with all key factors:<br/>·Asset utilisation<br/>·Workforce plan<br/>·Financial Income/cost/pricing</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Lack of operational command and control</td>
          <td class="border" style="padding: 10px; ">Activity has not been overseen and controlled in any of the hospitals by leaders until recently.  This has lead to a situation whereby individual clinicians operate according to their own practice, and not within set guidelines.</td>
          <td class="border" style="padding: 10px; ">The establishment of a Group HQ command and control centre linked to site-level command and control centres designed for planning operations, conducting oversight and intervention and driving operational efficiency in workforce and assets.</td>
          <td class="border" style="padding: 10px; ">Site-level Command and Control Centre will be based at KHUH to cover BOC and KH.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">No activity-based planning</td>
          <td class="border" style="padding: 10px; ">Forward-planning based on planned activity to coordinate Nursing staffing, Medical staffing and productive use of assets must improve across all hospitals.</td>
          <td class="border" style="padding: 10px; ">Planning must be based on booked activity.<br/>Non-elective activity must be assumed based on activity trends.</td>
          <td class="border" style="padding: 10px; ">Communications and control teams at each control area must focus on ensuring those booking cases are linked to those planning workforce rotas and deployment.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Lack of Operational Management to coordinate, control and communicate daily</td>
          <td class="border" style="padding: 10px; ">There are few, if any, dedicated professional managers in any of the hospitals. </td>
          <td class="border" style="padding: 10px; ">Professional managers must be deployed to enable smooth running of planned activity</td>
          <td class="border" style="padding: 10px; ">Potential to re-role excess Nursing Managers and Administrators to coordinate activity on an operational basis.</td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Lack of use of information systems</td>
          <td class="border" style="padding: 10px; ">The Group boasts 2 excellent HIS platforms.  However operational activity data is not regularly utilised to the fullest extent.</td>
          <td class="border" style="padding: 10px; ">Command and Control Planning Centres must be trained and guided to extract relevant data on a daily basis from the</td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Lack of Consultant Job Planning</td>
          <td class="border" style="padding: 10px; ">(So What? – duplication of effort, patients waiting in clinics while consultants do ward rounds).</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Obvious discrepancies between Colleagues</td>
          <td class="border" style="padding: 10px; ">Unbalanced workloads</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Resource Wastage</td>
          <td class="border" style="padding: 10px; ">Wasted Clinic resources and lack of operational coordination</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
</tr>

<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">9</td>
          <td class="border" style="padding: 10px; ">Pathways</td>
          <td class="border" style="padding: 10px; ">Inefficient</td>
          <td class="border" style="padding: 10px; ">Inefficient Pathways – day-case unit, endoscopy etc. – excess staffing, wasted resources</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
</tbody>
</table>

<div class="hello1">Negative Factors on Operations</div>

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Item </th>
          <th class="border px-4 py-2">Impact Area</th>
          <th class="border px-4 py-2">Consideration</th>
          <th class="border px-4 py-2">Deduction</th>
          <th class="border px-4 py-2">So What?</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Excess Workforce (confirmed by high % Workforce: Total Income and compared to productivity.</td>
          <td class="border" style="padding: 10px; ">Workforce costs are more than 80% of costbase.  This guarantees operating losses, and indicates excess and unnecessary staffing.</td>
          <td class="border" style="padding: 10px; ">Workforce must be reduced to match activity.</td>
          <td class="border" style="padding: 10px; ">Workforce reduction plan essential to support operational improvement.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Cumbersome Operational Structures</td>
          <td class="border" style="padding: 10px; ">Nursing staff structure in BDFH has 7 layers.</td>
          <td class="border" style="padding: 10px; ">Too many Nursing Managers restricts innovation and leadership.  It is also expensive. </td>
          <td class="border" style="padding: 10px; ">Convert Nursing structure to 2 strata.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Waiting Lists</td>
          <td class="border" style="padding: 10px; ">The main proof of inefficient planning is the fact there are waiting lists when there is so much unused capacity.  This confirms serious inefficiency.</td>
          <td class="border" style="padding: 10px; ">Waiting Lists indicate out of control operations.</td>
          <td class="border" style="padding: 10px; ">A complete plan to reduce waiting lists must be core to the operational improvement plan.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Poor patient experience</td>
          <td class="border" style="padding: 10px; ">Unmanaged operations lead to poor patient experience – for example Consultants booking clinics however attending ward rounds while patients wait to be seen in clinic.</td>
          <td class="border" style="padding: 10px; ">All operational activity must be planned and managed effectively.</td>
          <td class="border" style="padding: 10px; ">Operational improvement will lead to better patient experience and increased growth of turnover.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Underutilised capacity and financial waste</td>
          <td class="border" style="padding: 10px; ">Inadequate planning creates significant imbalances between utilisation of capacity within key areas such as OT.</td>
          <td class="border" style="padding: 10px; ">Underutilised capacity leads to exaggerated financial waste and losses due to bad planning.</td>
          <td class="border" style="padding: 10px; ">Optimised capacity demand data must drive all operational planning.  Control over bookings and over resource allocations must be clear.</td>
</tr>
</tbody>
</table>

<div class="hello1"><br/></div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">
            <span class="card-header">Interdependencies</span>
            <span class="card-total3">Intelligence – lead<br /><br />	
Workforce Impact<br /><br />
Operational Efficiency and Planning<br /><br />
Financial Efficiency<br /><br />	
</span>            
          </div>




</div>
</div>
</div>





<div v-if="stageSummary == 'aim'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">AIM</div>

<div class="new_stat-cards--item single no-hover" style="width: 100%">        
            <span class="card-total3">The aim of the operational improvement plan is to establish Group-wide processes to ensure systematic uplift of effective planning and management to deliver improved healthcare services to patients, demonstrating improved productivity, asset utilisation, workforce deployment and resource optimization and value for money.</span>            
          </div>


</div>
</div>
</div>





<div v-if="stageSummary == 'strategic execution'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->

<div class="hello1">Execution</div>


<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Concept</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">The concept will be to conduct a 3-phase approach to delivering ongoing operational improvement throughout the RMS Group of hospitals:</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Right-Size<br/>This will ensure the appropriate levels of productivity are applied to all Demand, and ensuring that the correct levels of<br>resources (Capacity) are right-sized to meet that activity.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Consolidate<br/>This will see the application of improved quality, patient-focus and generation of augmented management systems (including<br>the integration of the Group-wide application of ERP-HIS).</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Grow<br/>This phase will focus on the growth of key services benefitting the national health systems, the Bahraini population,<br>and the use of effective marketing, communications and Public Relations to grow the demand into RMS Group to maximise the full use<br>of the resource capacity.  Grow means growing demand to meet the existing capacity.  </td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">Additional growth will also mean the ability to acquire new services, for example Awali Hospital purchasing BDF RMS support as at June 2023.</td>
</tr>
</tbody>
</table>


<div class="hello1"><br/></div>

<div class="new_stat-cards--item single no-hover" style="width: 50%">
            <span class="card-header">Decisive Focus</span>
            <span class="card-total3">The main effort will be the establishment of an effective Group-level Command, Control and Communications office linked to<br>equivalent hospital-level C3 offices in KHUH (covering BOC), and MKCC.</span>            
          </div>
</div>
</div>
</div>





<div v-if="stageSummary == 'strategic ex phase 1'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 1 Right Size - Interdependent: Workforce, Finance</div>

<div class="new_stat-cards--item single no-hover" style="width: 65%">    
            <span class="card-total3">This phase is a natural follow-on from the information<br>build-up and planning of Stage 2.  The BDF Military<br>Hospital will be the lead entity, and will set the precedent<br>for others to coordinate concurrently.  During this phase,<br>HSi will</span>            
          </div>

          <div class="hello1"></div>

          <table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Delegation of Authority to ICS</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 1</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Command Control and Communications HQ</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 2</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Commence Implementation Operations</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 3</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Briefing Medical HODs</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 4</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Briefing Nursing Senior Staff</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 5</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Daily Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 6</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Daily Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 7</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Weekly Planning and Coordination Action</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 8</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">9</td>
          <td class="border" style="padding: 10px; ">Establish Group Operations Dashboard</td>
          <td class="border" style="padding: 10px; ">ICB</td>
          <td class="border" style="padding: 10px; ">Month 9</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>

<div v-if="stageSummary == 'strategic ex phase 2'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 2 Consolidation - Interdependent: Workforce, Finance, IT Integration</div>


<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Establish Group-Level weekly Operational Oversight</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">01-Sep</td>
          <td class="border" style="padding: 10px; ">Zoom Meeting updating planned Activity and Actual Delivered Activity versus Optimized Capacity Benchmarking</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Establish Hospital Waiting List Management Plans</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">01-Sep</td>
          <td class="border" style="padding: 10px; ">1.  Establish backlogs and regular referral activity by specialty.<br/>2.  Confirm % New-Follow Up increases for planning.<br/>3.  Confirm OT activity targets.<br/>4.  Confirm additional capacity/cost requirements where required to eradicate waiting lists</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Confirm Operational Requirements for all Specialties and Assets in all Hospitals. </td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">14-Sep</td>
          <td class="border" style="padding: 10px; ">HSi to propose.<br/>Interdependent Workforce.<br/>Interdependent Workforce<br/>Confirm to Workforce Plan exact volumes of staff that are surplus.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Establish fully optimised operational requirements.</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Confirm options for<br/>(a)  Re-purposing<br/>(b)  Upskilling<br/>(c)  Redundant</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Confirm Consolidation to Workforce Right-size Optimization</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; "></td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Confirm Quality Performance Management approach per hospital</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Liaise Quality Teams</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">7</td>
          <td class="border" style="padding: 10px; ">Confirm Live Group and Hospital level dashboards</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Subject to Stage 4 agreement (licensing)</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">8</td>
          <td class="border" style="padding: 10px; ">Confirm high value services to prepare for increased market exposure</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; ">30-Sep</td>
          <td class="border" style="padding: 10px; ">Best value services to generate increased revenues in Phase 3.</td>
</tr>
</tbody>
</table>





</div>
</div>
</div>





<div v-if="stageSummary == 'strategic ex phase 3'">
<div class="route-content">
<div class="new_stat-cards">
<!---- insert code here -------->
<div class="hello1">PHASE 3 Grow Operations - Interdependent: Finance, Workforce, Costing Coding, IT Integration</div>

<table class="table-primary">
<thead> 
     <tr>
          <th class="border px-4 py-2">Stage</th>
          <th class="border px-4 py-2">Action</th>
          <th class="border px-4 py-2">Responsible</th>
          <th class="border px-4 py-2">Timeline</th>
          <th class="border px-4 py-2">Remarks</th>
</tr>
</thead>
<tbody>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">1</td>
          <td class="border" style="padding: 10px; ">Establish Income Generation Target Operating Model</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Interdependent Finance</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">2</td>
          <td class="border" style="padding: 10px; ">Establish detailed Income Generation Private Practice Target Operating Model</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Work directly with Specialty Groups to drive up private practice volumes</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">3</td>
          <td class="border" style="padding: 10px; ">Establish full Income Growth Marketing Plan</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">All services</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">4</td>
          <td class="border" style="padding: 10px; ">Establish PR and Market Reach plan to drive income generation:<br/>MKCC Healthchecks and Screening,<br/>Specific Services Growth Plans: Bariatric, Joints, Cataracts, Angios, Endoscopy, Diagnostics<br/>
            General Healthchecks: Womens’ and Male checks,<br/>Screening Growth<br/>Medical Tourism, Target Markets:<br/>a)  Dubai<br/>b)  Eastern Region Saudi Arabia - Cardiac, Cardiology, Paediatrics, Gynaecology, Urology<br/>c)  United Kingdom: holiday/diagnostic/therapeutic treatments for those on long waiting lists: Angio, Endoscopy, Radiology</td>
          <td class="border" style="padding: 10px; ">MG/HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Social Media, Mainline media<br/>Target Private Market and win business<br/>Self-Pay Pricing deals<br/>Available Capacity will be identified following Phase 1 Right-Sizing</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">5</td>
          <td class="border" style="padding: 10px; ">Acquire new business additions: Awali, Kidney Centre, Long Term Care and more short-range opportunity targets.</td>
          <td class="border" style="padding: 10px; ">HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">HSi support by establishing full business planning and commercial modelling of pricing.</td>
</tr>
<tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px; ">6</td>
          <td class="border" style="padding: 10px; ">Establish proposals for increased market share from MoH using HSi Insight population health demand data to identify and secure opportunities</td>
          <td class="border" style="padding: 10px; ">MG/HSi</td>
          <td class="border" style="padding: 10px; "></td>
          <td class="border" style="padding: 10px; ">Subject to licensing</td>
</tr>
</tbody>
</table>




</div>
</div>
</div>





  

  

  
   
 </div>
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "executive summary",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },      
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      firstUpdate: false,    
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("operationalimprovementplan.vue before mounted");
  },
  mounted() {
    console.log("operationalimprovement plan mounted");  
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);      
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    
   h1(text) {
    return `<h1>${text} </h1>`
   },
    specialityHeader(header) {
      return header;
    },   
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "stage") {
        //this.apiPayload.apiString = "grandsummarytwo-collection";

        //console.log("stage: getData");

        //this.getMongoDBData();
        //this.fillData("workforceCost");
      }

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style>
.chartcontainer {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 400px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.hello1 {
color: #000000;
font-family: arial, sans-serif;
font-size: 16px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 30px;
}
</style>
